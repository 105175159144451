@import './asset/css/Header.scss';
@import './asset/css/Home.scss';
@import './asset/css/Book.scss';

* {
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    font-size: 14px;
    text-decoration: none;
    font-family: Arial, Helvetica, sans-serif;
}