.book_page {

    .book_heading {
        display: flex;
        text-align: center;
        justify-content: center;
        flex-direction: column;
        height: 140px;
        line-height: 20px;
        background: radial-gradient(#0a99af, #057586);
        color: white;

        strong {
            font-size: 2rem;
            margin-bottom: 5px;
        }
    }

    .book_section {
        margin: 50px 100px;

        h1 {
            font-size: 1.5rem;
            margin: 25px;
        }

        .book_container {
            height: 600px;
            display: grid;
            grid-template-rows: repeat(2, 1fr);
            grid-template-columns: repeat(5, 1fr);
            
            .book_card {
                border: 0.5px solid #d5dbdb;
                margin: 1px;

                .book_content {
                    .book_img {}

                    .book_name {}

                    .book_rating {}

                    .book_price {}
                }
            }
        }
    }
}

.book_nav {
    padding: 0px 20px;
    line-height: 33px;

    .nav_tabs {
        display: flex;
        flex-direction: row;

        .nav_link {
            font-size: 12px;
            color: #333;
            padding: 0 11px;
            font-weight: 530;
        }

        .nav_link:hover {
            color: orange;
            cursor: pointer;
        }

    }
}