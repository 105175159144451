.scroll {
    overflow: hidden;

    img {
        width: 100%;
    }
}

.welcome {
    display: flex;
    justify-content: center;
    margin-top: -350px;

    p {
        width: 95%;
        background-color: white;
        text-align: center;
        padding: 10px;

        a {
            color: #007185;
        }
    }
}

.adv {
    img {
        margin-top: 15px;
        width: 100%;
    }
}

.card_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    .card {
        margin-top: 20px;
        width: 350px;
        height: 420px;
        background-color: #fff;

        .card_content {
            display: flex;
            flex-direction: column;
            margin: 20px;

            img {
                margin: 10px 0px;
                height: 320px;
            }

            p {
                font-weight: 700;
                font-size: 1.2rem;
            }

            a {
                font-size: 0.9rem;
                color: #007185;
            }
        }
    }
}